<template>
  <div v-if="commonCodeByOrder && gameCount" class="mainBg">
    <section id="maincontent">
      <transition name="fade">
        <ui-swiper />
      </transition>
    </section>
    <section>
      <ul id="mainGameMenu">
        <li>
          <div class="main-txt">
            <h2>LIVE CASINO</h2>
            <h3>프리미엄 프라이빗 클럽</h3>
            <p>라이브 카지노를 경험하는 가장 완벽한 공간으로 당신을 초대합니다.</p>
            <button @click="goPageByName('listcasino')">play now</button>
          </div>
        </li>
        <!-- <li>
          <div class="main-txt">
            <h2>HOTEL CASINO</h2>
            <h3>호텔 카지노의 미래</h3>
            <p>본질적인 가치에 집중합니다.</p>
            <button @click="goPageByName('listhotel')">play now</button>
          </div>
        </li> -->
        <li>
          <div class="main-txt">
            <h2>SLOT GAMES</h2>
            <h3>프리미엄 프라이빗 클럽</h3>
            <p>슬롯을 경험하는 가장 완벽한 공간으로 당신을 초대합니다.</p>
            <button @click="goPageByName('listslot')">play now</button>
          </div>
        </li>
        <!-- <li>
          <div class="main-txt">
            <h2>DOMESTIC SPORTS</h2>
            <h3>최적화된 배팅 환경</h3>
            <p>익숙한 배팅 리스트를 제공합니다.</p>
            <button @click="goPageByName('listsports')">play now</button>
          </div>
        </li> -->
        <!-- <li>
          <div class="main-txt">
            <h2>MINI GAMES</h2>
            <h3>가볍고 심플한 게임 플레이</h3>
            <p>가장 트렌디한 경험을 선사합니다.</p>
            <button @click="goPageByName('listmini')">play now</button>
          </div>
        </li> -->
        <li>
          <div class="main-txt">
            <h2>PROMOTIONS</h2>
            <h3>모먼츠</h3>
            <p>PLAY CHASINO의 특권과 프리미엄 혜택</p>
            <button @click="goPageByName('notice')">view more</button>
          </div>
        </li>
      </ul>
    </section>
    <div class="mainPopWrap">
      <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
        <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
          <div class="closeWrap">
            <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
            <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
          </div>
          <img :src="item.bannerImg" />
        </div>
      </template>
    </div>
  </div>

   <!--div class="loginmodal">
      <div class="mloading-container">
         <div class="mloading"></div>
         <div class="mloading-text">loading</div>
      </div>
   </div-->
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'

import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'

import store from '@/store'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
    UiSwiper
  },
  created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    this.loadCashStatus()
  },
  data () {
    return {
      type: 'in',
      currentTab: '',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 5,
      selectedMenu: '',
      noticeListMaxCount: 5
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])

  },
  methods: {
    scrollToSection (sectionId) {
      const sectionElement = document.getElementById(sectionId)
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' })
      }
    },
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    thousand,
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    },
    onChangeType (type) {
      this.type = type
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style scoped>
.mt111 {height: 400px}
.loading {z-index: 10;position: absolute;top: 0;width: 100%;height: 310px;padding-top: 40px;display: flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.popmark {position: absolute;top: 11px;left: 11px;width: 46px;height: 20px;font-size: 12px;color: #fff;border-radius: 10px;background-color: #d73027;align-items: center;display: flex;justify-content: center;}
.newmark {position: absolute;top: 11px;left: 11px;width: 46px;height: 20px;font-size: 12px;color: #fff;border-radius: 10px;background-color: #4575b4;align-items: center;display: flex;justify-content: center;}
.rrdmark {position: absolute;top: 12px;right: 19px;width: 58px;height: 24px;font-size: 14px;color: #fff;border-radius: 12px;background-color: #d73027;align-items: center;display: flex;justify-content: center;}
.rblmark {position: absolute;top: 12px;right: 19px;width: 58px;height: 24px;font-size: 14px;color: #fff;border-radius: 12px;background-color: #4575b4;align-items: center;display: flex;justify-content: center;}
.gameKinds li h4 {margin: 52px 0 0 20px;font-size: 16px;font-weight: bold;color: #fff;}
.gameKinds li span {margin: 4px 0 0 20px;font-size: 12px;color: #fff;}
h2.mainnames {width: 1314px;margin: 0 auto;padding: 30px 0 20px;font-size: 30px;font-weight: bold;color: #000;}
.name em.casino {color: #dc4a42;}
.name em.slot {color: #f4d03b;}
.name em.hotel {color: #fc7c30;}
.name em.sport {color: #244694;}
.name em.mini {color: #693290;}
li:hover .name, li:hover .name em {color: #fff;}

ul.tabs {display: flex;justify-content: center;align-items: center;padding: 30px 0;width: 1314px;margin: 0 auto;gap: 5px;}
ul.tabs li {display: flex;align-items: center;justify-content: center;font-size: 14px;color: #fff;cursor: pointer;width: 148px;height: 30px;border: solid 1px #777;background: #111;}
.tab-content{margin: 0 auto;}
.tab-content.current{display: inherit;}
ul.tabs li:hover {border: 1px solid #e5972d;background: #333;}
ul.tabs li.current {color: #000;border: 1px solid #e5972d;background: #e5972d;}

.slotmodalwrap::-webkit-scrollbar {width: 10px;}
.slotmodalwrap::-webkit-scrollbar-thumb {background-color: none;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent;}
.slotmodalwrap::-webkit-scrollbar-track {background-color: none;border-radius: 10px;}
.slotmodal {background: #ededed;border: 1px solid #707070;border-radius: 30px;position: absolute;left: 50%;top: 50%; transform: translate(-50%,-50%);z-index: 1;width: 1314px;}
.slotmodal>h3 {width: 100%;position: relative;text-align: center;font-size: 30px;font-weight: bold;color: #fff;padding: 15px 0;background: #222;border-radius: 30px 30px 0 0;}
.slotmodal>h3::after {content: '';display: block;width: 100%;height: 17px;background-image: linear-gradient(to right, #319e8a, #1c594e);position: absolute;bottom: -25px;}
.slotmodal>h3 .close {position: absolute;right: 15px;top: 9px;height: 40px;}
.slotmodalwrap {max-height: 900px;overflow-y: auto;padding-top: 55px;}
.slotmodalwrap ul {padding: 0 65px 30px;display: flex;gap: 25px;justify-content: center;}
.slotmodalwrap ul li {transition: all ease 1s;cursor: pointer;}
.slotmodalwrap ul li:hover, .slotmodal ul li:focus, .slotmodal ul li:active {transform: scale(1.1);}
.slotmodalwrap ul li img {width: 100%;border-radius: 12px;border: 2px solid #319e8a;height: 200px;}

.mainPopWrap {display: flex;align-items: center;gap: 50px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 100;}
.mainPop {position: relative;min-width: 320px;background: #fff;border: 1px solid #777;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 430px;height: 640px;vertical-align: bottom;object-fit: cover;object-position: center;}

</style>
<style>
.swiper-button-next {background-image: url(../assets/img/slideright.png) !important;}
.swiper-button-prev {background-image: url(../assets/img/slideleft.png) !important;}
/*.mainSlide img{width:100%;}
.mainSlide .swiper-slide>a{width:100%;display:block;}
.mainSlide .swiper-slide>a img{display:block;width:100%;height: 400px;object-fit:cover;object-position:center;}
.swiper-button-next, .swiper-button-prev {bottom: 50px;top: auto;}
.swiper-button-next {
  background-image: url(../assets/img/slideright.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  right: 50%;
  bottom: 3px;
  margin-right: -640px;
  z-index: 11;
}
.swiper-button-prev {
  background-image: url(../assets/img/slideleft.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  bottom: 3px;
  margin-left: -640px;
  z-index: 11;
}
.swiper-button-next:after, .swiper-button-prev:after {content: '';}
.swiper-button-next:hover, .swiper-button-prev:hover {opacity: 1;}
.swiper-pagination {bottom: 0 !important;gap: 100px;background-color: ;height: 50px;display: flex;justify-content: center;align-items: center;}
.swiper-pagination-bullet {width: 105px; min-height: 19px; background: transparent; opacity: 0.3; color: #fff;}
.swiper-pagination-bullet-active {border-radius: 0;opacity: 1;}*/
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
